import { http, createConfig } from 'wagmi'
import { base, mainnet, optimism , bscTestnet } from 'wagmi/chains'
import { injected, metaMask, safe, walletConnect } from 'wagmi/connectors'

// const projectId = '<WALLETCONNECT_PROJECT_ID>'

export const config = createConfig({
    // chains: [mainnet, base],
    chains: [bscTestnet],
    connectors: [
        // injected(),
        // walletConnect({ projectId }),
        metaMask(),
        // safe(),
    ],
    transports: {
        [bscTestnet.id]: http(),
        // [mainnet.id]: http(),
        // [base.id]: http(),
    },
})