// routes
import { PATH_DASHBOARD } from './routes/paths';

// REGEX
export const NUM_REGEX = /\D/g;

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || 'http://exchange.dgval.ir/api';
export const HOST_API_STORAGE = (`${process.env.REACT_APP_HOST_API_KEY  }/storage/`) || 'http://exchange.dgval.ir/api/storage/';
export const EXCHANGE_WALLET_ADDRESS = process.env.REACT_APP_EXCHANGE_WALLET_ADDRESS || "0x15d7bdadf020e1cfb497ed0b9f859898c1019574"

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

export const ACTIVE_SELECT = [
  {label : "فعال" , value : true},
  {label : "غیرفعال" , value : false}
]

export const YES_NO_SELECT = [
  {label : "بله" , value : true},
  {label : "خیر" , value : false}
]

export const EVM_NETWORKS = [
  { label: 'اتریوم', value: 1 },
  { label: 'بایننس اسمارت چین', value: 56 },
  { label: 'آربیتروم', value: 42161 },
  { label: 'پالیگان (متیک)', value: 137 },
  { label: 'آوالنچ', value: 43114 },
  { label: 'فانتئم', value: 250 },
  { label: 'اوپیتیسم', value: 10 },
]


// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.general.app;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const MARKET_TYPE_OP = [
  {label : "اوردر بوک" , value : "limit"},
  {label : "خرید و فروش سریع" , value : "market"},
]

export const DOC_STATUS_ARR = [
  {label : "درحال بررسی" , value : 0, color : "warning"},
  {label : "تایید شده" , value : 1, color : "success"},
  {label : "رد شده" , value : 2, color : "error"},
]

// SETTINGS
// ----------------------------------------------------------------------

export const 
defaultSettings = {
  themeMode: 'dark',
  themeDirection: 'rtl',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};
