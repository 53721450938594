import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', meta, className = " rounded-xl mx-4 py-10", ...other }, ref) => {

  const { themeMode } = useSettings();
  
  return (
    <>
      <Helmet>
        <title>{`${title} | پنل`}</title>
        {meta}
      </Helmet>

      <Box className={`${ themeMode === "dark" ? "!bg-[#050A24]" : ""} ${className}`} ref={ref} {...other}>
        {children}
      </Box>
    </>
  )
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
  className: PropTypes.string,
};

export default Page;
