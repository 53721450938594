import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import useCollapseDrawer from './hooks/useCollapseDrawer';
import { PATH_DASHBOARD } from './routes/paths';
import { config } from './utils/commectWallet/config';

// ----------------------------------------------------------------------

export default function App() {
  const admin = useSelector(store => store.admin.data)
  const queryClient = new QueryClient()

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <WagmiProvider config={config}>
              <QueryClientProvider client={queryClient}>

                <ToastContainer
                  rtl={"true"}
                  toastClassName={"siteTempToast"}
                  position="bottom-right"
                />
                <NotistackProvider>
                  <MotionLazyContainer>
                    <ProgressBarStyle />
                    <ChartStyle />
                    {/* <Settings /> */}
                    <ScrollToTop />
                    <Router admin={admin} />
                  </MotionLazyContainer>
                </NotistackProvider>
              </QueryClientProvider>

            </WagmiProvider>

          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
