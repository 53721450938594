import axiosInstance from "../utils/axios";

// Profile

export const adminProfile = () => axiosInstance.get("/admin/profile",);

// Admin

export const createAdmin = (data) => axiosInstance.post("/admin/admin",data);

export const editAdmin = (id , data) => axiosInstance.put(`/admin/admin/${id}`,data);

export const getAllAdmin = (page = 1 , perPage = 10) => axiosInstance.get(`/admin/admin?page=${page}&perPage=${perPage}` );

export const getAdminDetail = (id) => axiosInstance.get(`/admin/admin/${id}` );

export const deleteAdmin = (id) => axiosInstance.delete(`/admin/admin/${id}` );

// Role

export const getAllRole = (page = 1 , perPage = 10) => axiosInstance.get(`/admin/role?page=${page}&perPage=${perPage}` );

export const getRoleDetail = (id) => axiosInstance.get(`/admin/role/${id}` );

export const createRole = (data) => axiosInstance.post(`/admin/role` , data );

export const editRole = (id , data) => axiosInstance.put(`/admin/role/${id}` , data );

export const deleteRole = (id) => axiosInstance.delete(`/admin/role/${id}` );

// Permission

export const getAllPermission = (page = 1 , perPage = 10) => axiosInstance.get(`/admin/permission?page=${page}&perPage=${perPage}` );