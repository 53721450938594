// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { permissions } from '../../../permission';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  coin: getIcon('bi--coin'),
  pair: getIcon('coins-circle'),
  card: getIcon('card'),
  faq: getIcon('faq'),
  admin: getIcon('admin'),
  role: getIcon('role'),
  network: getIcon('network'),
  blockchain: getIcon('blockchain'),
};

const navConfig = [
  {
    // subheader: 'مدیریت سایت',
    items: [
      { title: 'داشبورد', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },

      // Coins
      {
        title: 'فرصت های سرمایه گذاری',
        path: PATH_DASHBOARD.coin.root,
        icon: ICONS.coin,
        permission: permissions.Coin.read
      },

      // Pairs
      {
        title: 'جفت ارز ها',
        path: PATH_DASHBOARD.pair.root,
        icon: ICONS.pair,
        permission: permissions.Pair.read
      },
      {
        title: 'شبکه ها',
        path: PATH_DASHBOARD.network.root,
        icon: ICONS.network,
        permission: permissions.Network.read
      },
      {
        title: 'نشر توکن',
        path: PATH_DASHBOARD.token.root,
        icon: ICONS.blockchain,
        permission: permissions.Token.read
      },
      // TICKETS
      // {
      //   title: 'تیکت ها',
      //   path: PATH_DASHBOARD.ticket.list,
      //   icon: ICONS.chat,
      //   permission: ticketPermission.read
      // },
      {
        title: 'پروفایل توکن',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        permission: permissions.Blog.read
      },
      // {
      //   title: 'سوالات متداول',
      //   path: PATH_DASHBOARD.faq.list,
      //   icon: ICONS.faq,
      //   permission: permissions.Faq.read
      // },
      // {
      //   title: 'تماس باما',
      //   path: PATH_DASHBOARD.contactUs.list,
      //   icon: ICONS.chat,
      //   permission: ticketPermission.read
      // },
      // {
      //   title: 'بانک ها',
      //   path: PATH_DASHBOARD.bank.list,
      //   icon: ICONS.card,
      //   permission: ticketPermission.read
      // },

      // FINANCIAL
      {
        title: 'تراکنش ها',
        path: PATH_DASHBOARD.financial.root,
        icon: ICONS.banking,
        permission: permissions.Transaction.read,
        // children: [
        //   { title: 'خرید', path: PATH_DASHBOARD.financial.deposit.list },
        //   { title: 'برداشت', path: PATH_DASHBOARD.financial.withdraw.list },
        // ],
      },

      {
        title: 'کاربران',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        permission: permissions.User.read,
        children: [
          { title: 'لیست', path: PATH_DASHBOARD.user.list },
          // { title: 'مدارک ارسالی', path: PATH_DASHBOARD.user.verifyDoc.list },
          { title: 'کارت های بانکی', path: PATH_DASHBOARD.user.verifyCard.list },
          { title: 'حساب های بانکی', path: PATH_DASHBOARD.user.verifyAccountCard.list },
          // { title: 'مدارک ارسال شده', path: PATH_DASHBOARD.user.verifyDoc.list },
        ],
      },

      {
        title: 'ادمین ها',
        path: PATH_DASHBOARD.admin.root,
        icon: ICONS.admin,
        permission: permissions.Admin.read
      },


      {
        title: 'نقش ها',
        path: PATH_DASHBOARD.role.root,
        icon: ICONS.role,
        permission: permissions.Role.read
      },
    ],
  },
];

export default navConfig;
