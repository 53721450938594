/* eslint-disable no-unreachable */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import PermissionRoute from '../permission/permissionRoute';
import { permissions } from '../permission';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/admin/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<AdminList />}
                  permissions={permissions.Admin.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<AdminCreate />}
                  permissions={permissions.Admin.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<AdminCreate />}
                  permissions={permissions.Admin.update}
                />
            },
          ],
        },
        {
          path: 'financial',
          children: [
            { element: <Navigate to="/financial/list" replace />, index: true },
            {
              path: 'deposit',
              children: [
                { element: <Navigate to="/financial/deposit/list" replace />, index: true },
                {
                  path: 'list',
                  element:
                    <PermissionRoute
                      element={<DepositList />}
                      permissions={permissions.Transaction.read}
                    />
                },
              ]
            },
            {
              path: 'withdraw',
              children: [
                { element: <Navigate to="/financial/withdraw/list" replace />, index: true },
                {
                  path: 'list',
                  element:
                    <PermissionRoute
                      element={<WithdrawList />}
                      permissions={permissions.Transaction.read}
                    />
                },
                {
                  path: 'edit/:id',
                  element:
                    <PermissionRoute
                      element={<CreateNewWithdraw />}
                      permissions={permissions.Transaction.read}
                    />
                },
                {
                  path: 'show/:id',
                  element:
                    <PermissionRoute
                      element={<CreateNewWithdraw />}
                      permissions={permissions.Transaction.read}
                    />
                },
              ]
            },
            { path: 'list', element: <TransactionList /> },

          ],
        },
        {
          path: 'role',
          children: [
            { element: <Navigate to="/role/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<RoleList />}
                  permissions={permissions.Role.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewRole />}
                  permissions={permissions.Role.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewRole />}
                  permissions={permissions.PermissionRole.update}
                />
            },
            // { path: 'list', element: <RoleList /> },
            // { path: 'post/:title', element: <BlogPost /> },
            // { path: 'new', element: <CreateNewRole /> },
            // { path: 'edit/:id', element: <CreateNewRole /> },
          ],
        },

        {
          path: 'ticket',
          children: [
            { element: <Navigate to="/ticket/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<TicketList />}
                  permissions={permissions.Transaction.read}
                />
            },
            // {
            //   path: 'new',
            //   element:
            //     <PermissionRoute
            //       element={<ShowTicketChat />}
            //       permissions={permissions.Transaction.create}
            //     />
            // },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<ShowTicketChat />}
                  permissions={permissions.Transaction.update}
                />
            },
            // { path: 'list', element: <TicketList /> },
            // { path: 'edit/:id', element: <ShowTicketChat /> },
          ],
        },

        {
          path: 'network',
          children: [
            { element: <Navigate to="/network/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<NetworksList />}
                  permissions={permissions.Network.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewNetwork />}
                  permissions={permissions.Network.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewNetwork />}
                  permissions={permissions.Network.update}
                />
            },
            // { path: 'list', element: <TicketList /> },
            // { path: 'edit/:id', element: <ShowTicketChat /> },
          ],
        },

        {
          path: 'token',
          children: [
            { element: <Navigate to="/token/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<TokenList />}
                  permissions={permissions.Token.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewToken />}
                  permissions={permissions.Token.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewToken />}
                  permissions={permissions.Token.update}
                />
            },
            {
              path: 'show/:id',
              element:
                <PermissionRoute
                  element={<CreateNewToken />}
                  permissions={permissions.Token.update}
                />
            },
            // { path: 'list', element: <TicketList /> },
            // { path: 'edit/:id', element: <ShowTicketChat /> },
          ],
        },

        {
          path: 'faq',
          children: [
            { element: <Navigate to="/faq/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<FaqsList />}
                  permissions={permissions.Faq.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewFaq />}
                  permissions={permissions.Faq.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewFaq />}
                  permissions={permissions.Faq.update}
                />
            },
            // { path: 'list', element: <TicketList /> },
            // { path: 'edit/:id', element: <ShowTicketChat /> },
          ],
        },

        {
          path: 'blog',
          children: [
            { element: <Navigate to="/blog/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<BlogsList />}
                  permissions={permissions.Blog.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewBlog />}
                  permissions={permissions.Blog.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewBlog />}
                  permissions={permissions.Blog.update}
                />
            },
            // { path: 'list', element: <TicketList /> },
            // { path: 'edit/:id', element: <ShowTicketChat /> },
          ],
        },

        {
          path: 'bank',
          children: [
            { element: <Navigate to="/bank/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<BanksList />}
                  permissions={permissions.Bank.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewBank />}
                  permissions={permissions.Bank.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewCoin />}
                  permissions={permissions.Bank.update}
                />
            },
            // { path: 'list', element: <TicketList /> },
            // { path: 'edit/:id', element: <ShowTicketChat /> },
          ],
        },

        {
          path: 'coin',
          children: [
            { element: <Navigate to="/coin/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<CoinsList />}
                  permissions={permissions.Coin.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewCoin />}
                  permissions={permissions.Coin.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewCoin />}
                  permissions={permissions.Coin.update}
                />
            },
            // { path: 'list', element: <TicketList /> },
            // { path: 'edit/:id', element: <ShowTicketChat /> },
          ],
        },

        {
          path: 'pair',
          children: [
            { element: <Navigate to="/pair/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<PairsList />}
                  permissions={permissions.Pair.read}
                />
            },
            {
              path: 'new',
              element:
                <PermissionRoute
                  element={<CreateNewPair />}
                  permissions={permissions.Pair.create}
                />
            },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<CreateNewPair />}
                  permissions={permissions.Pair.update}
                />
            },
            // { path: 'list', element: <TicketList /> },
            // { path: 'edit/:id', element: <ShowTicketChat /> },
          ],
        },

        {
          path: 'contact-us',
          children: [
            { element: <Navigate to="/contact-us/list" replace />, index: true },
            {
              path: 'list',
              element:
                <PermissionRoute
                  element={<ContactUsList />}
                  permissions={permissions.Contactus.read}
                />
            },
            // {
            //   path: 'new',
            //   element:
            //     <PermissionRoute
            //       element={<ShowTicketChat />}
            //       permissions={permissions.Contactus.create}
            //     />
            // },
            {
              path: 'edit/:id',
              element:
                <PermissionRoute
                  element={<ShowTicketChat />}
                  permissions={permissions.Contactus.update}
                />
            },
            // { path: 'list', element: <TicketList /> },
            // { path: 'edit/:id', element: <ShowTicketChat /> },
          ],
        },
        {
          path: 'country',
          children: [
            { element: <Navigate to="/country/list" replace />, index: true },
            { path: 'list', element: <CountryList /> },
            { path: 'new', element: <CreateNewCountry /> },
            { path: 'edit/:id', element: <CreateNewCountry /> },
          ],
        },
        {
          path: 'language',
          children: [
            { element: <Navigate to="/language/list" replace />, index: true },
            { path: 'list', element: <LanguageList /> },
            { path: 'new', element: <CreateNewLanguage /> },
            { path: 'edit/:id', element: <CreateNewLanguage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'edit/:id', element: <CreateNewUser /> },
            {
              path: 'verify-doc',
              children: [
                { element: <Navigate to="/user/verify-doc/list" replace />, index: true },
                { path: 'list', element: <UserVerifyDocList /> },
                { path: 'edit/:id', element: <ShowUserKYC /> },
              ]

            },
            {
              path: 'verify-card',
              children: [
                { element: <Navigate to="/user/verify-card/list" replace />, index: true },
                { path: 'list', element: <UserVerifyCardList /> },
                { path: 'edit/:id', element: <ShowUserCard /> },
              ]

            },
            {
              path: 'verify-account-card',
              children: [
                { element: <Navigate to="/user/verify-account-card/list" replace />, index: true },
                { path: 'list', element: <UserVerifyAccountCardList /> },
                { path: 'edit/:id', element: <ShowUserAccountCard /> },
              ]

            },
            // { path: 'new', element: <CreateNewLanguage /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// ADMIN
const AdminList = Loadable(lazy(() => import('../pages/admin Management/AdminList')));
const AdminCreate = Loadable(lazy(() => import('../pages/admin Management/AdminCreate')));

// ROLE
const RoleList = Loadable(lazy(() => import('../pages/role/list')));
const CreateNewRole = Loadable(lazy(() => import('../pages/role/create')));

// FINANCIAL
const TransactionList = Loadable(lazy(() => import('../pages/financial/transactions')));
const DepositList = Loadable(lazy(() => import('../pages/financial/deposit/list')));
const WithdrawList = Loadable(lazy(() => import('../pages/financial/withdraw/list')));
const CreateNewWithdraw = Loadable(lazy(() => import('../pages/financial/withdraw/create')));

// TICKET
const TicketList = Loadable(lazy(() => import('../pages/tickets/list')));
const ShowTicketChat = Loadable(lazy(() => import('../pages/tickets/chat')));

// Network
const NetworksList = Loadable(lazy(() => import('../pages/networks/list')));
const CreateNewNetwork = Loadable(lazy(() => import('../pages/networks/create')));

// Faq
const FaqsList = Loadable(lazy(() => import('../pages/faq/list')));
const CreateNewFaq = Loadable(lazy(() => import('../pages/faq/create')));

// Blog
const BlogsList = Loadable(lazy(() => import('../pages/blog/list')));
const CreateNewBlog = Loadable(lazy(() => import('../pages/blog/create')));

// Bank
const BanksList = Loadable(lazy(() => import('../pages/bank/list')));
const CreateNewBank = Loadable(lazy(() => import('../pages/bank/create')));

// Coin
const CoinsList = Loadable(lazy(() => import('../pages/coin/list')));
const CreateNewCoin = Loadable(lazy(() => import('../pages/coin/create')));

// Pairs
const PairsList = Loadable(lazy(() => import('../pages/pair/list')));
const CreateNewPair = Loadable(lazy(() => import('../pages/pair/create')));

// TICKET
const ContactUsList = Loadable(lazy(() => import('../pages/contactUs/list')));

// TOKEN
const TokenList = Loadable(lazy(() => import('../pages/token/list')));
const CreateNewToken = Loadable(lazy(() => import('../pages/token/create')));

// USER
const UserList = Loadable(lazy(() => import('../pages/user/list')));
const CreateNewUser = Loadable(lazy(() => import('../pages/user/create')));
const UserVerifyDocList = Loadable(lazy(() => import('../pages/user/verifyDoc/list')));
const ShowUserKYC = Loadable(lazy(() => import('../pages/user/verifyDoc/create')));
const UserVerifyCardList = Loadable(lazy(() => import('../pages/user/verify-card/list')));
const ShowUserCard = Loadable(lazy(() => import('../pages/user/verify-card/create')));
const UserVerifyAccountCardList = Loadable(lazy(() => import('../pages/user/verifyAccountCard/list')));
const ShowUserAccountCard = Loadable(lazy(() => import('../pages/user/verifyAccountCard/create')));

// Country
const CountryList = Loadable(lazy(() => import('../pages/country/list')));
const CreateNewCountry = Loadable(lazy(() => import('../pages/country/create')));

// Language
const LanguageList = Loadable(lazy(() => import('../pages/language/list')));
const CreateNewLanguage = Loadable(lazy(() => import('../pages/language/create')));

// MAIN
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
